import Axios from "axios";

var ContaServicoMixinService = {
    methods: {
        getContaServico(args) {
            return Axios.get(
                this.CriarUrl({
                    resource: "api/conta-servico/filtra?",
                    params: args
                }),
                { withCredentials: true }
            );
        },

        putContaServico(id, body) {
            return Axios.put(this.CriarUrl({ resource: `api/conta-servico/${id}` }), body, { withCredentials: true });
        },
        
        postContaServico(body) {
            return Axios.post(this.CriarUrl({ resource: `api/conta-servico` }), body, { withCredentials: true });
        },

        putRefreshPasswordContaServico(id) {
            return Axios.put(this.CriarUrl({ resource: `api/conta-servico/refresh-password/${id}` }), { withCredentials: true });
        },
    }
};

export default ContaServicoMixinService;
