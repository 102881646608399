<template>
    <unicom-linha :espacamento="false">
        <unicom-coluna :lg="12">
            <unicom-cartao :class="`cartao-conta-servico ${tamanhoTela}`">
                <div slot="body">
                    <TabelaContaServico ref="tabelaContaServico" @mostrar-modal="value => mostrarModalConta(value)" @nova-senha="id => novaSenha(id)" />
                </div>
            </unicom-cartao>
            <ModalContaServico
                ref="modalConta"
                v-model="mostrarModal"
                :tipo="tipoModal"
                @salvar-edicao="salvarEdicao()"
                @salvar="valor => salvar(valor)"
                @fechar="mostrarModal = false"
            />
            <unicom-modal-sucesso
                v-model="mostrarModalSucessoEdicao"
                titulo="As alterações foram salvas!"
                @fechar="mostrarModalSucessoEdicao = false"
            />
            <ModalSucesso v-model="mostrarModalSucesso" :objeto="objetoConta" @fechar="mostrarModalSucesso = false" :titulo="tituloSucesso"/>
        </unicom-coluna>
    </unicom-linha>
</template>

<script>
import TabelaContaServico from "./Shared/TabelaContaServico";
import ModalContaServico from "./Shared/ModalContaServico";
import ModalSucesso from "./Shared/ModalSucessoContaServico";
import ContaServicoMixinService from "./services/ContaServicoMixinService";

export default {
    mixins: [ContaServicoMixinService],
    components: {
        TabelaContaServico,
        ModalContaServico,
        ModalSucesso
    },
    data() {
        return {
            tamanhoJanela: 0,
            mostrarModal: false,
            tipoModal: "",
            objetoConta: {},
            mostrarModalSucesso: false,
            mostrarModalSucessoEdicao: false,
            tituloSucesso: 'Conta de serviço criada!'
        };
    },
    created() {
        window.addEventListener("resize", this.redimensionar);
        this.redimensionar();
    },
    destroyed() {
        window.removeEventListener("resize", this.redimensionar);
    },

    methods: {
        mostrarModalConta(item) {
            if (item.tipo === "EDITAR") {
                this.$refs.modalConta.inserirDadosObjeto(item.objeto);
            }
            this.tipoModal = item.tipo;
            this.mostrarModal = true;
        },
        novaSenha(id){
            this.putRefreshPasswordContaServico(id)
                .then(response => {
                    if (response.status === 200) {
                        this.salvarNovaSenha(response.data);
                    }
                })
        },
        redimensionar() {
            this.tamanhoJanela = window.innerWidth;
        },
        salvarNovaSenha(objeto) {
            this.objetoConta = objeto;
            this.tituloSucesso = 'Senha alterada com sucesso!'
            this.mostrarModalSucesso = true;
            this.$refs.tabelaContaServico.listarContaServicos("");
        },
        salvar(objeto) {
            this.objetoConta = objeto;
            this.tituloSucesso = 'Conta de serviço criada!'
            this.mostrarModalSucesso = true;
            this.$refs.tabelaContaServico.listarContaServicos("");
            this.$refs.tabelaContaServico.campoPesquisa = '';
        },
        salvarEdicao() {
            this.mostrarModalSucessoEdicao = true;
            this.$refs.tabelaContaServico.listarContaServicos("");
            this.$refs.tabelaContaServico.campoPesquisa = '';
        }
    },
    computed: {
        tamanhoTela() {
            if (this.tamanhoJanela < 576) {
                return "ativo mobile";
            } else {
                return "web";
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.cartao-conta-servico {
    min-height: 400px;
    border-radius: 20px;
}
</style>
