<template>
    <div>
        <unicom-modal v-model="mostrar" :largura="500" fechar @input="valor => fecharModal(valor)" class="modal">
            <div slot="header">
                <h3 class="fonte-primaria titulo negrito">{{ tipo }} CONTA DE SERVIÇO</h3>
            </div>
            <div slot="body" class="container-corpo">
                <ValidationObserver ref="validate">
                    <unicom-linha :espacamento="false" justificarItem="start">
                        <unicom-coluna sm="12" md="12">
                            <validation-provider rules="required" mode="eager" v-slot="{ errors }">
                                <unicom-campo-texto
                                    v-model="objetoConta.nome"
                                    titulo="Nome de serviço *"
                                    placeholder="Insira nome do serviço dessa conta"
                                    maxCaracteres="50"
                                    apenasTextoNumero
                                    :mensagemErro="errors[0]"
                                />
                            </validation-provider>
                        </unicom-coluna>
                    </unicom-linha>
                </ValidationObserver>

                <unicom-linha :espacamento="false" justificarItem="start" style="margin-top:24px">
                    <unicom-coluna sm="12" md="12">
                        <unicom-campo-texto
                            v-model="objetoConta.descricao"
                            titulo="Descrição"
                            placeholder="Insira uma descrição opcional"
                            maxCaracteres="62"
                            apenasTextoNumero
                        />
                    </unicom-coluna>
                </unicom-linha>

                <unicom-linha :espacamento="false" justificarItem="start" style="margin-top:24px">
                    <unicom-coluna sm="12" md="12">
                        <unicom-autocomplete
                            v-model="objetoConta.unimed"
                            :valorData="objetoConta.unimed"
                            :items="listaUnimeds"
                            titulo="Unimed vinculada"
                            textoDoItem="codigoNome"
                            placeholder="Todas Unimeds"
                        />
                    </unicom-coluna>
                </unicom-linha>
                
                <unicom-linha :espacamento="false" justificarItem="start" style="margin-top:24px">
                    <unicom-coluna sm="12" md="12">
                        <unicom-switch
                            titulo="Receber login do usuário logado."
                            v-model="objetoConta.recebeLogin"
                            tooltip="Quando ativado, será possível mostrar o Login do usuário para a Auditoria."
                            :personalizarTooltip="true"
                            :color="'#000'"
                            :disabled="false"
                            :number="10"
                            @input="valor => receberUsuario(valor)"
                        >
                            <div slot="tooltip-texto-switch">
                                <p>Quando ativado, será possível mostrar o</p>
                                <p>Login do usuário para a Auditoria.</p>
                            </div>
                        </unicom-switch>
                    </unicom-coluna>
                </unicom-linha>

                <unicom-linha :espacamento="false" justificarItem="start" style="margin-top:24px">
                    <unicom-coluna sm="12" md="12">
                        <unicom-switch
                            titulo="A aplicação poderá registrar novos usuários."
                            v-model="objetoConta.registraNovosUsuarios"
                            tooltip="Quando ativado, a conta de serviço terá permissão para cadastrar usuários."
                            :personalizarTooltip="true"
                            :color="'#000'"
                            :disabled="false"
                            :number="10"
                            @input="valor => (objetoConta.registraNovosUsuarios = valor)"
                        >
                            <div slot="tooltip-texto-switch">
                                <p>Quando ativado, a conta de serviço terá permissão</p>
                                <p>para cadastrar usuários.</p>
                            </div>
                        </unicom-switch>
                    </unicom-coluna>
                </unicom-linha>

                <unicom-linha
                    :espacamento="false"
                    justificarItem="start"
                    style="margin-top:24px"
                    v-if="tipo === 'EDITAR'"
                >
                    <unicom-coluna sm="12" md="12">
                        <unicom-switch
                            titulo="Status da conta de serviço"
                            v-model="ativo"
                            tooltip="Para uma conta de aplicaçao INTERNA, o status será o mesmo dessa aplicaçao."
                            :personalizarTooltip="true"
                            :color="'#000'"
                            :number="100"
                            @input="valor => atualizarStatusContaServico(valor)"
                        >
                            <div slot="tooltip-texto-switch">
                                <p>Para uma conta de aplicaçao INTERNA,</p>
                                <p>o status será o mesmo dessa aplicaçao.</p>
                            </div>
                        </unicom-switch>
                    </unicom-coluna>
                </unicom-linha>
            </div>
            <div slot="footer" class="footer" style="margin-top:24px">
                <unicom-linha :espacamento="false" justificarItem="center">
                    <unicom-coluna :md="5" style="margin-right:24px;">
                        <unicom-botao
                            class="fonte-secundaria texto"
                            tipo="contornado-primario"
                            :bloco="true"
                            @click="mostrar = false"
                            >CANCELAR</unicom-botao
                        >
                    </unicom-coluna>
                    <unicom-coluna :md="5">
                        <unicom-botao
                            class="fonte-secundaria texto"
                            :bloco="true"
                            @click="salvar"
                            :desabilitado="habilitarCadastrar"
                        >
                            SALVAR ALTERAÇÕES
                        </unicom-botao>
                    </unicom-coluna>
                </unicom-linha>
            </div>
        </unicom-modal>

        <unicom-modal-confirmacao
            v-model="mostrarModalConfirmacaoAuditoria"
            textoBotaoCancelar="Desativar"
            textoBotaoConfirmar="Não desativar"
            @botao-cancelar="desativarLoginConta"
            @botao-confirmar="naoDesativarLoginConta"
            :tipoBotaoCancelar="true"
            @fechar="mostrarModalConfirmacaoAuditoria = false"
        >
            <div slot="corpo-confirmacao">
                <p>
                    Ao desativar, a <span class="texto-vermelho negrito">Auditória</span> não poderá identificar o
                    usuário logado na aplicação referente a esta conta de serviço.
                </p>
            </div>
        </unicom-modal-confirmacao>

        <unicom-modal-loading v-model="carregamentoOverlay" />
        <ModalAviso v-model="mostrarAviso" @fechar="fecharAviso" />
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ModalAviso from "./ModalAviso";
import ContaServicoMixinService from "../services/ContaServicoMixinService";

export default {
    mixins: [ContaServicoMixinService],

    components: {
        ValidationObserver,
        ValidationProvider,
        ModalAviso
    },

    props: {
        value: {
            type: Boolean,
            required: true
        },
        tipo: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            carregamentoOverlay: false,
            mostrar: this.value,
            mostrarAviso: false,
            mostrarModalConfirmacaoAuditoria: false,
            ativo: false,
            objetoConta: {
                id: null,
                nome: "",
                descricao: "",
                recebeLogin: false,
                registraNovosUsuarios: false,
                unimed: ""
            },
            listaUnimeds: [],
        };
    },

    beforeMount(){
        this.getUnimedsCombo();
    },

    methods: {
        fecharModal(valor) {
            if (!valor) {
                this.$emit("fechar");
            }
        },

        salvar() {
            this.carregamentoOverlay = true;
            this.objetoConta.descricao === ""
                ? (this.objetoConta.descricao = null)
                : (this.objetoConta.descricao = this.objetoConta.descricao);

            this.objetoConta.unimed = this.unimedVinculada;

            if (this.tipo === "EDITAR") {
                this.atualizarContaServico(this.objetoConta);
            } else {
                this.cadastrarContaServico(this.objetoConta);
            }
            this.mostrar = false;
        },

        limpar() {
            this.objetoConta = {
                nome: "",
                descricao: "",
                recebeLogin: false,
                registraNovosUsuarios: false,
                unimed: ""
            };
            this.ativo = false;
        },

        inserirDadosObjeto(objeto) {
            this.objetoConta.nome = objeto.nome;
            this.objetoConta.descricao = objeto.descricao;
            this.objetoConta.recebeLogin = objeto.recebeLogin;
            this.objetoConta.registraNovosUsuarios = objeto.registraNovosUsuarios;
            this.ativo = objeto.ativo;
            this.objetoConta.id = objeto.id;

            this.objetoConta.unimed = objeto.hasOwnProperty('unimed') ? `${objeto.unimed.codigo} - ${objeto.unimed.nome}` : '' ;

        },

        atualizarStatusContaServico(valor) {
            this.ativo = valor;
        },

        receberUsuario(valor) {
            this.objetoConta.recebeLogin = valor;
            if (this.objetoConta.recebeLogin && this.tipo === "CRIAR") {
                this.mostrarAviso = true;
            } else if (!this.objetoConta.recebeLogin) {
                this.mostrarModalConfirmacaoAuditoria = true;
            }
        },

        desativarLoginConta() {
            this.objetoConta.recebeLogin = false;
        },

        naoDesativarLoginConta() {
            this.objetoConta.recebeLogin = true;
        },

        desativarStatus() {
            this.ativo = false;
        },

        naoDesativarStatus() {
            this.ativo = true;
        },

        fecharAviso() {
            this.mostrarAviso = false;
        },

        atualizarContaServico(objeto) {
            this.putContaServico(objeto.id, Object.assign(objeto, { ativo: this.ativo }))
                .then(response => {
                    if (response.status === 200) {
                        this.$emit("salvar-edicao");
                    }
                })
                .finally(() => {
                    this.carregamentoOverlay = false;
                });
        },

        cadastrarContaServico(objeto) {
            this.postContaServico(objeto)
                .then(response => {
                    if (response.status === 200) {
                        this.$emit("salvar", response.data);
                    }
                })
                .finally(() => {
                    this.carregamentoOverlay = false;
                });
        },

        getUnimedsCombo() {
            this.listaUnimeds = this.getUnimedsList();
        },
    },
    watch: {
        value() {
            this.mostrar = this.value;
            if (!this.mostrar) {
                this.limpar();
            }
        }
    },

    computed: {
        habilitarCadastrar() {
            return this.objetoConta.nome ? false : true;
        }, 
        unimedVinculada(){
            let unimed = this.listaUnimeds.find(unimed => `${unimed.codigo} - ${unimed.nome}` === this.objetoConta.unimed);
            return unimed ? { ...unimed, id: unimed.codigo} : null;
        }
    }
};
</script>

<style lang="scss" scoped>
h3 {
    color: #00995d;
    margin-bottom: 30px;
}

.texto-vermelho {
    color: #f0666f;
}
</style>
