<template>
    <unicom-modal v-model="mostrar" :largura="562" fechar @input="valor => fecharModal(valor)" class="modal">
        <div slot="header">
            <unicom-linha :espacamento="false" justificarItem="center">
                <unicom-coluna sm="auto" md="auto">
                    <h3 class="fonte-secundaria titulo negrito">Atenção!</h3>
                </unicom-coluna>
            </unicom-linha>
        </div>
        <div slot="body" class="container-corpo">
            <unicom-linha :espacamento="false" justificarItem="center">
                <unicom-coluna sm="auto" md="auto">
                    <unicom-icone nome="clipboard" :tamanho="90" />
                </unicom-coluna>
            </unicom-linha>
            <unicom-linha :espacamento="false" justificarItem="center" class="subtitulo fonte-secundaria texto">
                <unicom-coluna sm="auto" md="11">
                    <p align="center">
                        Para exibir o usuário logado em <span class="texto-verde negrito">Auditória</span>,
                        <span class="negrito">é necessário que a aplicação esteja preparada </span>para enviá-lo por
                        parâmetro.
                    </p>
                </unicom-coluna>
            </unicom-linha>
        </div>
        <div slot="footer" class="footer" style="margin-top:24px">
            <unicom-linha :espacamento="false" justificarItem="center">
                <unicom-coluna :md="5">
                    <unicom-botao class="fonte-secundaria texto" :bloco="true" @click="continuar"
                        >CONTINUAR</unicom-botao
                    >
                </unicom-coluna>
            </unicom-linha>
        </div>
    </unicom-modal>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            required: true
        }
    },

    data() {
        return {
            mostrar: this.value
        };
    },

    methods: {
        fecharModal(valor) {
            if (!valor) {
                this.$emit("fechar");
            }
        },

        continuar() {
            this.mostrar = false;
            this.$emit("fechar");
        }
    },
    watch: {
        value() {
            this.mostrar = this.value;
        }
    }
};
</script>

<style lang="scss" scoped>
h3 {
    color: #60656c;
    margin-bottom: 5px;
}

.subtitulo {
    font-size: 14px;
    padding: 15px;
}

.texto-verde {
    color: #00995d;
}
</style>
