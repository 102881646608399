<template>
    <unicom-modal-sucesso v-model="mostrarModalSucesso" :titulo="titulo" @fechar="fecharModal()">
        <div slot="subtitulo">
            <unicom-linha :espacamento="false" justificarItem="center">
                <unicom-coluna :md="10">
                    <div class="campo-aplicacao">
                        <unicom-campo-texto v-model="login" titulo="Login da conta de serviço" :desativado="true" />
                    </div>
                    <div class="copiar-aplicacao" @click="copy('Login', login)">
                        <unicom-icone nome="copy" :tamanho="22" />
                    </div>
                </unicom-coluna>
            </unicom-linha>

            <unicom-linha :espacamento="false" justificarItem="center">
                <unicom-coluna :md="10">
                    <div class="campo-aplicacao">
                        <unicom-campo-texto v-model="senha" titulo="Senha da conta de serviço" :desativado="true" />
                    </div>
                    <div class="copiar-aplicacao" @click="copy('Senha', senha)">
                        <unicom-icone nome="copy" :tamanho="22" />
                    </div>
                </unicom-coluna>
            </unicom-linha>

            <toast :message="toastText" ref="refToast"></toast>
        </div>
    </unicom-modal-sucesso>
</template>

<script>
import Toast from "../../../shared/Toast/Toast.vue";

export default {
    components: {
        Toast
    },

    props: {
        value: {
            type: Boolean,
            required: true
        },
        objeto: {
            type: Object,
            required: false,
            default: () => {
                return {
                    loginResponse: {
                        login: "",
                        senha: ""
                    }
                };
            }
        },
        titulo: {
            type: String,
            required: false,
            default: "Conta de serviço criada!"
        }
    },

    data() {
        return {
            mostrarModalSucesso: this.value,
            contaServico: "",
            login: "",
            senha: "",
            toastText: "Valor copiado com sucesso!"
        };
    },

    methods: {
        fecharModal(valor) {
            if (!valor) {
                this.$emit("fechar");
            }
        },
        copy(label, texto) {
            this.$refs.refToast.hideToast();
            navigator.clipboard.writeText(texto);

            this.toastText = `${label} copiado com sucesso!`;
            this.$refs.refToast.showToast();
        }
    },
    watch: {
        value() {
            this.mostrarModalSucesso = this.value;
            if (this.value) {
                (this.login = this.objeto.loginResponse.usuario), (this.senha = this.objeto.loginResponse.senha);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.campo-aplicacao {
    float: left;
    margin-top: 14px;
    box-sizing: border-box;
    width: 85%;
}

.copiar-aplicacao {
    float: left;
    margin-top: 45px;
    margin-left: 1%;
    box-sizing: border-box;
    width: 10%;
    color: #ccc;
    cursor: pointer;

    &:hover {
        color: #00995d;
    }
}
</style>
