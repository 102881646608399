<template>
    <div>
        <ContaServico />
    </div>
</template>

<script>
import ContaServico from "../components/contaServico/ContaServico";
export default {
    components: {
        ContaServico
    }
};
</script>

<style lang="scss" scoped></style>
