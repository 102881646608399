<template>
    <div>
        <unicom-tabela
            class="tabela"
            :carregamento="loading"
            :icones="iconesUsadosTabela"
            :itensPorPagina="['5', '10', '15', '20']"
            :ordem="ordem"
            :pagina="pagina"
            :qtPaginas="qtPaginas"
            :qtResultados="qtResultados"
            :rows="rows"
            :cabecalho="cabecalho"
            :textoExibindo="'resultados'"
            @troca-elemento-pagina="item => trocaElementoPagina(item)"
            @ordena="item => ordenar(item)"
            @troca-pagina="pagina => trocaPagina(pagina)"
            @acao="item => acao(item)"
            @acao-icone="item => acaoIcone(item)"
        >
            <div slot="header">
                <unicom-linha :espacamento="true" :justificarItem="'end'">
                    <unicom-coluna :md="8">
                        <unicom-barra-pesquisa
                            v-model="campoPesquisa"
                            placeholder="Buscar nome da conta de serviço..."
                            @pesquisar="valor => pesquisar(valor)"
                        />
                    </unicom-coluna>
                    <unicom-coluna :md="3">
                        <unicom-botao :bloco="true" class="fonte-secundaria texto" @click="criarConta">
                            CRIAR CONTA DE SERVIÇO
                        </unicom-botao>
                    </unicom-coluna>
                </unicom-linha>
            </div>
        </unicom-tabela>
    </div>
</template>

<script>
import ContaServicoMixinService from "../services/ContaServicoMixinService";

export default {
    mixins: [ContaServicoMixinService],

    data() {
        return {
            loading: false,
            campoPesquisa: "",
            ordem: "desc",
            campoOrdenado: "",
            itensPorPagina: 5,
            pagina: 1,
            qtPaginas: 1,
            qtResultados: 0,
            iconesUsadosTabela: [
                {
                    nome: "edit",
                    tooltipNome: "Editar"
                },
                {
                    nome: "refresh-cw",
                    tooltipNome: "Renovar senha"
                }
            ],
            cabecalho: [
                {
                    texto: "Nome do serviço",
                    icone: "maximize-2"
                },
                {
                    texto: "Descrição da conta"
                },
                {
                    texto: "Login"
                },
                {
                    texto: "Unimed"
                },
                {
                    texto: "Status"
                },
                {
                    texto: "Informa usuário"
                },
                {
                    texto: "Cadastra usuário",
                    icone: "maximize-2"
                },
                {
                    texto: "Ações"
                }
            ],
            rows: [],
            aplicacao: "",
            objetoConta: null,
            listasContas: [],
            mostrarModal: false,
            itemColuna: {},
            mock: true
        };
    },
    async beforeMount() {
        this.paginar();
    },
    methods: {
        montarTabela(objeto) {
            this.listasContas = [];
            this.rows = [];

            this.listasContas = objeto.conteudo;
            this.listasContas.forEach(item => {
                this.rows.push({
                    nomeAplicacao: {
                        direcaoEsquerda: true,
                        texto: item.nome,
                        largura: 18,
                        altura: 18,
                        qtdMaxCaracteres: 16
                    },
                    descricaoDaConta: {
                        texto: item.descricao,
                        qtdMaxCaracteres: 18
                    },
                    login: {
                        texto: item.loginResponse.usuario,
                        feather: true,
                        qtdMaxCaracteres: 12,
                        style: { color: "#CCC" },
                        tooltip: "Copiar",
                        icone: "copy",
                        direcaoEsquerda: false,
                        tamanho: 20,
                        classIcone: "transacao-estilo"
                    },
                    unimedVinculada: {
                        texto: item.unimed ? `${item.unimed.codigo} - ${item.unimed.nome}` : "",
                        qtdMaxCaracteres: 20
                    },
                    status: {
                        tipo: "verdadeiroOuFalso",
                        valor: item.ativo
                    },
                    informaUsuario: {
                        tipo: "verdadeiroOuFalso",
                        valor: item.recebeLogin
                    },
                    cadastraUsuario: {
                        tipo: "verdadeiroOuFalso",
                        valor: item.registraNovosUsuarios
                    }
                });
            });
            this.pagina = objeto.pagina;
            this.qtPaginas = objeto.qtPaginas;
            this.qtResultados = objeto.qtResultados;
        },
        trocaElementoPagina(item) {
            this.itensPorPagina = item;
            this.pagina = 1;
            this.paginar();
        },

        ordenar(item) {
            switch (item.index) {
                case 0:
                    this.campoOrdenado = "nome";
                    break;
                case 5:
                    this.campoOrdenado = "registraNovosUsuarios";
                    break;
            }
            this.ordem = item.ordem;
            this.paginar();
        },

        trocaPagina(item) {
            this.pagina = item.pagina;
            this.paginar();
        },

        acao(item) {
            switch (item.acao) {
                case 0:
                    this.objetoConta = this.listasContas[item.id];
                    this.$emit("mostrar-modal", { tipo: "EDITAR", objeto: this.objetoConta });
                    break;
                case 1:
                    this.campoPesquisa = "";
                    this.$emit("nova-senha", this.listasContas[item.id].id);
                    break;
                default:
                    break;
            }
        },

        criarConta() {
            this.$emit("mostrar-modal", { tipo: "CRIAR", objeto: {} });
        },

        acaoIcone(item) {
            switch (item.coluna) {
                case 2:
                    this.copy(item);
                    break;
                case 3:
                    this.copy(item);
                    break;
            }
        },

        paginar() {
            this.listarContaServicos(this.getArgs());
        },
        pesquisar(valor) {
            if (valor) {
                const result = this.buscaContasServicos(valor);
                this[result] = valor;
            } else {
                this.aplicacao = "";
            }
            this.pagina = 1;
            this.paginar();
        },

        copy(item) {
            this.rows[item.index].login.tooltip = "Copiado!";
            navigator.clipboard.writeText(item.textoColuna);

            setTimeout(() => (this.rows[item.index].login.tooltip = "Copiar"), 2000);
        },

        listarContaServicos(args) {
            this.loading = true;
            this.getContaServico(args)
                .then(response => {
                    this.montarTabela(response.data);
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        getArgs() {
            return {
                direcaoOrdenacao: this.ordem,
                ordenacao: this.campoOrdenado,
                pagina: this.pagina,
                tamanhoPagina: this.itensPorPagina,
                aplicacao: this.aplicacao
            };
        }
    }
};
</script>

<style lang="scss">
.transacao-estilo {
    div {
        &:hover {
            cursor: pointer;
            color: #1daa7d;
        }
    }
}
</style>
