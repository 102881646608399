<template>
    <div class="gerenciar-conta-container">
        <unicom-linha :espacamento="false">
            <unicom-coluna>
                <h2 class="fonte-primaria negrito titulo">GERENCIAR CONTAS DE SERVIÇO</h2>
                <small class="fonte-secundaria texto"
                    >Início > <span class="negrito">Gerenciar Contas de Serviço</span></small
                >
            </unicom-coluna>
        </unicom-linha>
        <unicom-linha :espacamento="false">
            <unicom-coluna>
                <div class="conteudo-principal-container">
                    <ListarContaServico />
                </div>
            </unicom-coluna>
        </unicom-linha>
    </div>
</template>

<script>
import ListarContaServico from "./shared/ListarContaServico";

export default {
    components: {
        ListarContaServico
    },
    data() {
        return {};
    }
};
</script>

<style lang="scss" scoped>
.gerenciar-conta-container {
    margin: 35px;
    box-sizing: border-box;
    width: calc(100% - 70px);
    color: #60656c;

    h2 {
        margin-bottom: 8px;
    }

    .conteudo-principal-container {
        margin-top: 24px;
    }
}
</style>
