<template>
    <div id="toast" class="fonte-primaria " :class="classShow">{{ message }}</div>
</template>

<script>
export default {
    props: {
        message: {
            type: String,
            default: "Valor copiado com sucesso!"
        },
        timer: {
            type: Number,
            default: 3000,
            notes: "Tempo para ficar exibindo o toast (3 segundos por padrão)"
        }
    },
    data() {
        return {
            classShow: ""
        };
    },
    methods: {
        hideToast() {
            this.classShow = "";
        },

        showToast() {
            this.classShow = "show";
            setTimeout(() => (this.classShow = ""), this.timer);
        }
    }
};
</script>

<style scoped>
#toast {
    visibility: hidden;
    min-width: 140px;
    margin-left: -125px;
    background-color: #245f40;
    color: #fff;
    text-align: center;
    border-radius: 8px;
    padding: 15px;
    position: fixed;
    z-index: 1;
    left: 50%;
    bottom: 30px;
}

#toast.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }
    to {
        bottom: 30px;
        opacity: 1;
    }
}

@keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }
    to {
        bottom: 30px;
        opacity: 1;
    }
}

@-webkit-keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 1;
    }
    to {
        bottom: 0;
        opacity: 0;
    }
}

@keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 1;
    }
    to {
        bottom: 0;
        opacity: 0;
    }
}
</style>
